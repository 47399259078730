<h3><b>{{ 'title.resolve' | translate | async }}</b> - {{this.data.name}}</h3>
<p mat-dialog-content>
   {{"messages.confirmation" | translate | async }}
</p>
<p>{{"message.alertoutofoccurance" | translate | async }}</p>
<div mat-dialog-actions>
  <vue-basic-button (clicked)="closeDialog()">{{ "button.cancel" |translate | async }} </vue-basic-button>
  <vue-flat-button [loading]="loadingStatus" (clicked)="confirmDialogue()"> {{ "button.confirm" | translate | async  }}</vue-flat-button>
</div>

